

 const apptheme = {
    primaryColor: '#1A3B4E',
    secondaryColor : '#FFFFFF',
    tertiaryColor : '#275469',
    quaternaryColor : '#ec9c05',
    quintenaryColor : '#44c485',
    senaryColor : '#339263',
    septenary : '#666666'

} 

export default apptheme
